body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sidebarfixed {
  position: fixed !important;
}

#maincontent {
  margin-bottom: 100px!important;
}

#subcontent {
  padding-left: 0rem!important;
}

@media only screen and (max-width:1199.98px) {
  #subcontent {
    padding-left: 3rem!important;
  }
}
@media only screen and (max-width: 767.98px) {
  #subcontent {
    padding-left: 1rem!important;
  }
}

.scrollTopbtn {
  padding: 10px;
  left: auto !important;
}

.fs-7 {
  font-size: 0.875rem!important
}

.tableStudent {
  min-width: 20rem !important;
}
.required:after {
  content: "*";
  color: red;
}

.icon {
  height: 1rem;
  width: auto;
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

.heading {
  color: #8F3D1F !important;
}

.bg-theme {
  background-color: #27313A !important;
}

.bg-bear {
  background-color: #F9A11B !important;
}

.bg-searchbox {
  background-color: #e8e8e8 !important;
}

.bearIcon {
  width: 2rem;
}

.listDetail > p {
  margin: 0.3rem 0.25rem !important;
  /* max-width: 10rem !important; */
  font-size: 15px;
}

.shortListDetail > p {
  margin: 0.3rem 0.25rem !important;
  /* max-width: 10rem !important; */
  font-size: 12px;
}

.lettered-avatar {
  font-size: 16px !important;
}

.paginateBtn,
.paginateBtn li a {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.paginateBtn > li {
  background-color: #F9A11B !important;
  border: 2px solid #f9d81b;
  border-radius: 1rem;
  margin: 0.1rem;
  list-style: none;
  color: #fdfdfd;
}

.paginateBtn a {
  text-decoration: none;
  color: #fdfdfd;
  height: 3rem;
  min-width: 3rem;
  font-size: 18px;
  padding: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 1rem;
  box-shadow: inset 0px 1px 0px 0px rgba(255, 255, 255, 0.35);
}

.paginateBtn a:hover {
  background: rgba(255, 255, 255, 0.2);
  border-top-color: rgba(0, 0, 0, 0.35);
  border-bottom-color: rgba(0, 0, 0, 0.5);
}
.paginateBtn a:focus,
.paginateBtnActive a {
  padding-top: 4px;
  border-left-width: 1px;
  background: rgba(255, 255, 255, 0.15);
  box-shadow: inset 0px 2px 1px 0px rgba(0, 0, 0, 0.25);
}

.css-i4bv87-MuiSvgIcon-root{
  font-size:1rem !important;
  color:#999999;
}

.MuiSvgIcon-root{
  font-size:1rem !important;
  color:#999999;
}